import React from 'react'
import errorScreen from "../../assets/images/errorScreen.png";
import { Box, Button, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

function FallBack() {
    return (
        <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", px: 2, textAlign: "center" }}>
            <Box sx={{ mb: 2, height: { xs: "300px", md: "400px" }, width: { xs: "300px", md: "400px" } }}>
                <img src={errorScreen} width="100%" height="100%" alt="error" />
            </Box>
            <Typography sx={{ fontSize: { xs: 28, md: 40 }, lineHeight: { xs: "34px", md: "54px" }, fontWeight: 700, color: "#395987" }} >
                Oops! Something Went Wrong
            </Typography>
            <Typography sx={{ fontSize: 20, lineHeight: "22px", fontWeight: 500, color: "#6F7482", mt: 2.5, mb: 3.75, px: 2 }} >
                Please reload page and try again later
            </Typography>
            <Button
                variant="contained"
                sx={{ borderRadius: "24px", px: 12, py: 1.5 }}
                startIcon={<RefreshIcon />}
                onClick={() => window.location.reload()}
            >
                Reload
            </Button>
        </Box >
    )
}

export default FallBack;