
import {requestForToken,onMessageListener} from "./firebase"
import React,{useState,useEffect} from 'react'

import toast, { Toaster } from 'react-hot-toast';
import useApplicationState from "../hooks/useApplicationState";

const Notification = () => {
    const [notification, setNotification] = useState({title: '', body: ''});
    const { getNotifications } = useApplicationState();
    const notify = () =>  toast(<ToastDisplay/>);
    function ToastDisplay() {
      return (
        <div>
          <p><b>{notification?.title}</b></p>
          <p>{notification?.body}</p>
        </div>
      );
    };
  
    useEffect(() => {
      if (notification?.title ){
       notify()
    }
      requestForToken()
      onMessageListener()
      .then((payload) => {
        setNotification({title: payload?.data?.title, body: payload?.data?.body});
        getNotifications(); 
      })
      .catch((err) => console.log('failed: ', err));
    }, [notification])
  
    return (
       <Toaster/>
    )
  }
  
  export default Notification;
