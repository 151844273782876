import { useState } from 'react';

export function useRecaptcha() {
    const [attempts, setAttempts] = useState(0);
    const [showRecaptcha, setShowRecaptcha] = useState(false);

    const handleCountForRecaptcha = () => {
        setAttempts(pre => pre + 1);

        if (attempts >= 2) {
            setShowRecaptcha(pre => !pre);
        }
    };

    const handleRecaptchaChange = (token) => {
        setShowRecaptcha(pre => !pre);
        setAttempts(pre => 0);
    };

    return {
        attempts,
        setAttempts,
        showRecaptcha,
        handleCountForRecaptcha,
        handleRecaptchaChange,
    };
}
