import loadable from '@loadable/component';
import { Loading } from '../components/CommonSmallComponents/CommonSmallComponents';

const Signup = loadable(() => import('./Signup'), { fallback: <Loading /> });
const Signup2 = loadable(() => import('./Signup2'), { fallback: <Loading /> });
const Profile = loadable(() => import('./Profile'), { fallback: <Loading /> });
const Login = loadable(() => import('./Login'), { fallback: <Loading /> });
const UsersList = loadable(() => import('./UsersList'), {
  fallback: <Loading />,
});
const JobsAndResponses = loadable(() => import('./JobsAndResponses'), {
  fallback: <Loading />,
});
const ManageJobsAndResponses = loadable(
  () => import('./ManageJobsAndRespones'),
  { fallback: <Loading /> }
);
const CreateVacancy = loadable(() => import('./CreateHotVacancies'), {
  fallback: <Loading />,
});
const PostJob = loadable(() => import('./PostJob'), { fallback: <Loading /> });
const JobTitle = loadable(() => import('./JobTitle'), {
  fallback: <Loading />,
});
const ToastTitle = loadable(() => import('./ToastTitle'), {
  fallback: <Loading />,
});
const RegisterHospital = loadable(() => import('./RegisterHospital'), {
  fallback: <Loading />,
});
const HospitalLogin = loadable(() => import('./HospitalLogin'), {
  fallback: <Loading />,
});
const HospitalDashboard = loadable(() => import('./HospitalDashboard'), {
  fallback: <Loading />,
});
const ApplicantDetails = loadable(() => import('./ApplicantDetails'), {
  fallback: <Loading />,
});
const SavedSearches = loadable(() => import('./SavedSearches'), {
  fallback: <Loading />,
});
const PersonalFolders = loadable(() => import('./PersonalFolders'), {
  fallback: <Loading />,
});
const Notifications = loadable(() => import('./Notifications'), {
  fallback: <Loading />,
});
const Reminders = loadable(() => import('./Reminders'), {
  fallback: <Loading />,
});
const SubscriptionStatus = loadable(() => import('./SubscriptionStatus'), {
  fallback: <Loading />,
});
const CompanyProfile = loadable(() => import('./CompanyProfile'), {
  fallback: <Loading />,
});
const AdvanceSearch = loadable(() => import('./AdvanceSearch'), {
  fallback: <Loading />,
});
const SearchResumeResult = loadable(() => import('./SearchResumeResult'), {
  fallback: <Loading />,
});
const AccountDetails = loadable(() => import('./AccountDetails'), {
  fallback: <Loading />,
});
const PricingPlans = loadable(() => import('./PricingPlans'), {
  fallback: <Loading />,
});
const Checkout = loadable(() => import('./Checkout'), {
  fallback: <Loading />,
});
const JobSearchList = loadable(() => import('./SearchJobResult'), {
  fallback: <Loading />,
});
const SingleJob = loadable(() => import('./SingleJob'), {
  fallback: <Loading />,
});
const ForgotPassword = loadable(() => import('./Login/ForgotPassword'), {
  fallback: <Loading />,
});
const ContactUs = loadable(() => import('./ContactUs'), {
  fallback: <Loading />,
});
const BasicDetails = loadable(() => import('./BasicDetails'), {
  fallback: <Loading />,
});
const ProfileHome = loadable(() => import('./ProfileHome'), {
  fallback: <Loading />,
});
const SavedJobs = loadable(() => import('./SavedJobs'), {
  fallback: <Loading />,
});
const AccountSettings = loadable(() => import('./AccountSettings'), {
  fallback: <Loading />,
});
const ProfileListFolder = loadable(() => import('./ProfileListFolder'), {
  fallback: <Loading />,
});
const EditBasicDetails = loadable(() => import('./Profile/EditBasicDetails'), {
  fallback: <Loading />,
});
const PrimaryHomeContainer = loadable(() => import('./PrimaryHome'), {
  fallback: <Loading />,
});
const FAQ = loadable(() => import('./FAQ'), { fallback: <Loading /> });
const ManageAlert = loadable(() => import('./ManageAlert'), {
  fallback: <Loading />,
});
const AboutUs = loadable(() => import('./AboutUs'), { fallback: <Loading /> });
const TermAndUsage = loadable(() => import('./TermAndUsage'), {
  fallback: <Loading />,
});
const PrivacyPolicy = loadable(() => import('./PrivacyPolicy'), {
  fallback: <Loading />,
});
const PaymentStatus = loadable(
  () => import('./Checkout/PaymentStatus/index.jsx'),
  { fallback: <Loading /> }
);
const JobseekerFeature = loadable(() => import('./JobseekerFeature'), {
  fallback: <Loading />,
});
const JobSeekersSearchJobs = loadable(() => import('./JobSeekersSearchJobs'), {
  fallback: <Loading />,
});
const RecruiterFeature = loadable(() => import('./RecruiterFeature'), {
  fallback: <Loading />,
});
const CreateYourProfile = loadable(() => import('./CreateYourProfile'), {
  fallback: <Loading />,
});
const JobseekerEducationInfo = loadable(
  () => import('./ProfileRegisterInfo/JobseekerEducationInfo'),
  { fallback: <Loading /> }
);
const JobseekerExperienceInfo = loadable(
  () => import('./ProfileRegisterInfo/JobseekerExperienceInfo'),
  { fallback: <Loading /> }
);
const UploadResumeInfo = loadable(
  () => import('./ProfileRegisterInfo/UploadResumeInfo'),
  { fallback: <Loading /> }
);
const FeatureComingSoon = loadable(() => import('./FeatureComingSoon'), {
  fallback: <Loading />,
});
const RefundCancellationPolicy = loadable(
  () => import('./RefundCancellationPolicy'),
  { fallback: <Loading /> }
);
const HowToPurchase = loadable(() => import('./HowToPurchase'), {
  fallback: <Loading />,
});
const Team = loadable(() => import('./Team'), { fallback: <Loading /> });
const PressAndMedia = loadable(() => import('./PressAndMedia'), {
  fallback: <Loading />,
});
const AddBasicDetails = loadable(
  () => import('./ProfileRegisterInfo/BasicDetails'),
  { fallback: <Loading /> }
);
const Blogs = loadable(() => import('./Blog'), { fallback: <Loading /> });
const BlogDetails = loadable(() => import('./BlogDetails'), {
  fallback: <Loading />,
});
const PostPremiumJobMedLinkSystemUser = loadable(
  () => import('./PostPremiumJobMedLinkSystemUser'),
  { fallback: <Loading /> }
);
const JobseekerNotifications = loadable(
  () => import('./JobseekerNotifications'),
  { fallback: <Loading /> }
);
const HospitalSignUp = loadable(
  () => import('./HospitalSignUp/HospitalSignUp.view'),
  { fallback: <Loading /> }
);
const FeaturedHospital = loadable(
  () => import('./FeaturedHospital/FeaturedHospital.view'),
  { fallback: <Loading /> }
);
const LandingPage = loadable(() => import('./LandingPage'), {
  fallback: <Loading />,
});
const AccountSettingJR = loadable(() => import('./AccountSettingJR'), {
  fallback: <Loading />,
});
const JobPostPricing = loadable(() => import('./JobPostPricing'), {
  fallback: <Loading />,
});
const AppliedJobs = loadable(() => import('./AppliedJobs'), {
  fallback: <Loading />,
});
const RecruiterFeaturesPostAJob = loadable(
  () => import('./RecruiterFeaturesPostAJob'),
  { fallback: <Loading /> }
);
const RecruiterFeaturesBrandingSolutions = loadable(
  () => import('./RecruiterFeaturesBrandingSolutions'),
  { fallback: <Loading /> }
);
const RecruiterFeaturesSearchCandidate = loadable(
  () => import('./RecruiterFeaturesSearchCandidate'),
  { fallback: <Loading /> }
);
const RecruitersStaffingSolutions = loadable(
  () => import('./RecruitersStaffingSolutions'),
  { fallback: <Loading /> }
);
const RecruiterFeaturesSearchCandidateForm = loadable(
  () => import('./RecruiterFeaturesSearchCandidateForm'),
  { fallback: <Loading /> }
);
const RecruitersStaffingSolutionsForm = loadable(
  () => import('./RecruitersStaffingSolutionsForm'),
  { fallback: <Loading /> }
);
const RecruiterFeaturesBrandingSolutionsForm = loadable(
  () => import('./RecruiterFeaturesBrandingSolutionsForm'),
  { fallback: <Loading /> }
);
const RecruiterFeaturesFormSubmissionSuccess = loadable(
  () => import('./RecruiterFeaturesFormSubmissionSuccess'),
  { fallback: <Loading /> }
);
const CAMPAIGNHOMEPAGE = loadable(() => import('./CampaignHomePage'), {
  fallback: <Loading />,
});
const DownloadApp = loadable(() => import('./DownloadApp'), {
  fallback: <Loading />,
});
const RecruiterHome = loadable(() => import('./RecruiterHome'), {
  fallback: <Loading />,
});
const Courses = loadable(() => import('./Courses'), { fallback: <Loading /> });
const SavedJobSeekerProfile = loadable(
  () => import('./SavedJobSeekerProfile'),
  { fallback: <Loading /> }
);
const JobRole = loadable(() => import('./JobRole'), {
  fallback: <Loading />,
});

export {
  Login,
  Signup,
  Signup2,
  Profile,
  UsersList,
  JobsAndResponses,
  ManageJobsAndResponses,
  CreateVacancy,
  PostJob,
  HospitalDashboard,
  ApplicantDetails,
  JobTitle,
  ToastTitle,
  RegisterHospital,
  HospitalLogin,
  SavedSearches,
  PersonalFolders,
  Notifications,
  Reminders,
  SubscriptionStatus,
  CompanyProfile,
  AdvanceSearch,
  SearchResumeResult,
  AccountDetails,
  PricingPlans,
  Checkout,
  JobSearchList,
  SingleJob,
  ForgotPassword,
  ContactUs,
  BasicDetails,
  ProfileHome,
  SavedJobs,
  AccountSettings,
  ProfileListFolder,
  EditBasicDetails,
  PrimaryHomeContainer,
  FAQ,
  ManageAlert,
  AboutUs,
  TermAndUsage,
  PrivacyPolicy,
  PaymentStatus,
  JobseekerFeature,
  JobSeekersSearchJobs,
  RecruiterFeature,
  CreateYourProfile,
  JobseekerEducationInfo,
  JobseekerExperienceInfo,
  UploadResumeInfo,
  FeatureComingSoon,
  RefundCancellationPolicy,
  HowToPurchase,
  Team,
  PressAndMedia,
  AddBasicDetails,
  PostPremiumJobMedLinkSystemUser,
  Blogs,
  BlogDetails,
  JobseekerNotifications,
  HospitalSignUp,
  FeaturedHospital,
  LandingPage,
  AccountSettingJR,
  JobPostPricing,
  AppliedJobs,
  RecruiterFeaturesPostAJob,
  RecruiterFeaturesBrandingSolutions,
  RecruiterFeaturesSearchCandidate,
  RecruitersStaffingSolutions,
  RecruiterFeaturesSearchCandidateForm,
  RecruiterFeaturesFormSubmissionSuccess,
  RecruitersStaffingSolutionsForm,
  RecruiterFeaturesBrandingSolutionsForm,
  CAMPAIGNHOMEPAGE,
  DownloadApp,
  RecruiterHome,
  Courses,
  SavedJobSeekerProfile,
  JobRole,
};
