export const breakWordStyle = {
    wordWrap: "break-word",
    overflowWrap: "break-word",
    wordBreak: "break-word"
};

export const loginSignupButtonGroupStyle = {
    width: "100%",
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: "center",
    alignItems: "center",
    gap: { xs: 1.5, sm: 3 },
}

export const loginSignupButtonStyle = {
    width: "100%",
    py: 1.625,
    borderRadius: 3,
    fontWeight: "600 !important",
    border: "2px solid #395987",
    letterSpacing: 1 
}

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "12px",
    width: { xs: "95%", sm: "430px !important" },
    gap: 1,
    py: { xs: 2, sm: 3.75 },
    outline: 'none'
};

export const uploadResumeStyle = {
    outline: "none",
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "6px",
    boxShadow: 1,
    px: 7,
    py: 5,
};

export const uploadResumeStyleResponsive = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "6px",
    boxShadow: 1,
    px: 2,
    py: 2,
};