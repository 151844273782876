import { API, graphqlOperation } from "aws-amplify";
import jwt_decode from "jwt-decode";

export const JobseekerSubscriptions = ({ getNotifications }) => {
  let access_token = localStorage.getItem("accessToken");
  jwt_decode(access_token, { header: true });
  let decoded = jwt_decode(access_token);
  const SUB_ON_PUBLISH_NOTIFICATIONS = `subscription MySubscription {
     onPublishNotification(userID: "${decoded?.sub}") {
       count
       userID
       createdAt
       description
       nID
       redirectTo
       status
     }
   }`;

  const subscription = API.graphql(
    graphqlOperation(SUB_ON_PUBLISH_NOTIFICATIONS)
  ).subscribe({
    next: ({ provider, value }) => {
      try {
        getNotifications();
      } catch (err) {
        console.log(err, "errorrrrr");
      }
    },

    error: (error) => console.log(error),
  });
};
