import React from 'react';
import { createTheme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Box,useMediaQuery } from "@mui/material";
import TestimonialRf from './TestimonialRf';

const theme = createTheme();
const useStyles = makeStyles({
    jobRelatedInfoSection: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
});
function JobRelatedInfoCarousal() {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box className={classes.jobRelatedInfoSection} sx={{m:"auto"}}>
            <TestimonialRf />
        </Box>
    )
}

export default JobRelatedInfoCarousal