import loadable from '@loadable/component'
import { Loading } from './CommonSmallComponents/CommonSmallComponents';

import FallBack from "./ErrorBoundary/ErrorBoundary";
const Header = loadable(() => import("./Header"), { fallback: <Loading /> });
const FooterLandingPage = loadable(() => import("./FooterLandingPage"), { fallback: <Loading /> });
const SignupForm = loadable(() => import("./SignupForm"), { fallback: <Loading /> });
const Signup2Form = loadable(() => import("./Signup2Form"), { fallback: <Loading /> });
const Welcome = loadable(() => import("./Welcome"), { fallback: <Loading /> });
const ProfileSnap = loadable(() => import("./ProfileSnap"), { fallback: <Loading /> });
const JobseekerBanner = loadable(() => import("./JobseekerSignUp/JobSeekerBanner"), { fallback: <Loading /> });
const JobseekerRegister = loadable(() => import("./JobseekerSignUp/JobseekerRegister"), { fallback: <Loading /> });
const JobseekerLogin = loadable(() => import("./JobseekerSignUp/JobseekerLogin"), { fallback: <Loading /> });
const CreateJobseekerProfile = loadable(() => import("./CreateProfileForms/CreateJobseekerProfile"), { fallback: <Loading /> });
const JobseekerBasicDetails = loadable(() => import("./CreateProfileForms/JobseekerBasicDetails"), { fallback: <Loading /> });
const JobseekerEducations = loadable(() => import("./CreateProfileForms/JobseekerEducations"), { fallback: <Loading /> });
const JobseekerExperiences = loadable(() => import("./CreateProfileForms/JobseekerExperiences"), { fallback: <Loading /> });
const UploadResume = loadable(() => import("./CreateProfileForms/UploadResume"), { fallback: <Loading /> });
const NeedHelpModal = loadable(() => import("./NeedHelpModal"), { fallback: <Loading /> });
const BackdropLoader = loadable(() => import("./BackDropLoader/BackDropLoader"), { fallback: <Loading /> });

export { FallBack, Header, FooterLandingPage, SignupForm, Signup2Form, Welcome, ProfileSnap, JobseekerBanner, JobseekerRegister, JobseekerLogin, CreateJobseekerProfile, JobseekerBasicDetails, JobseekerEducations, JobseekerExperiences, UploadResume, NeedHelpModal, BackdropLoader };
