import { Box, InputBase, Typography, Button, TextField, Autocomplete, FormHelperText, Snackbar, Modal, CircularProgress, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, forwardRef, useEffect, useImperativeHandle } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./CommonSmallComponents.css";
import SearchIcon from '@mui/icons-material/Search';
import MuiAlert from "@mui/material/Alert";
import CancelIcon from '@mui/icons-material/Cancel';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HistoryIcon from '@mui/icons-material/History';
import ClearIcon from '@mui/icons-material/Clear';
import { uploadResumeStyle, uploadResumeStyleResponsive } from "../CommonStyles/CommonStyles";

const iframeWrapper = { position: "relative", pb: "56.25%", overflow: "hidden" };
const iframe = { overflow: "hidden", border: 0, alignSelf: "center", position: "absolute", top: 0, left: 0, width: "100%", height: "100%" };

export const Loading = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])
    return (
        <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="loader"></div>
        </div>
    )
};

export const PermissionRequired = () => {
    return (
        <Box sx={{ height: "90vh", maxWidth: "md", mt: 4, mx: "auto" }}>
            <Typography sx={{ fontSize: 24, fontWeight: 600, color: "gray", px: { xs: 2, sm: 0 } }}>You need permission.</Typography>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: "gray", px: { xs: 2, sm: 0 } }}>Oops! Looks like you do not have permission to view this page.</Typography>
        </Box>
    )
};

export const VideoPlayer = ({ videoLink }) => {
    const regex =
        /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube(?:-nocookie)?\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/)?([\w\-]+)|^(?:(?:https?:)?\/\/)?(?:www\.)?dailymotion\.com\/(?:video|hub)\/([\w\-]+)|^(?:(?:https?:)?\/\/)?(?:www\.)?vimeo\.com\/?(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/)?([\d]+)$/i;
    const match = videoLink?.match(regex);

    if (!match) {
        return <p>Invalid video link</p>;
    }

    const videoId = match[1] || match[2] || match[3];

    if (videoLink.includes("youtube") || videoLink.includes("youtu.be")) {
        return (
            <Box sx={{ ...iframeWrapper }}>
                <iframe
                    style={{ ...iframe }}
                    title="YouTube Video Player"
                    src={`https://www.youtube-nocookie.com/embed/${videoId}`}
                    frameborder="0"
                    allowfullscreen
                ></iframe>
            </Box>
        );
    } else if (videoLink.includes("dailymotion")) {
        return (
            <Box sx={{ ...iframeWrapper }}>
                <iframe
                    style={{ ...iframe }}
                    title="Dailymotion Video Player"
                    src={`//www.dailymotion.com/embed/video/${videoId}`}
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </Box>
        );
    } else if (videoLink.includes("vimeo")) {
        return (
            <Box sx={{ ...iframeWrapper }}>
                <iframe
                    style={{ ...iframeWrapper }}
                    title="Vimeo Video Player"
                    src={`https://player.vimeo.com/video/${videoId}`}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                ></iframe>
            </Box>
        );
    } else {
        return <p>Invalid video platform</p>;
    }
};

export const CustomSelectInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        borderRadius: "4px",
        position: "relative",
        border: "1px solid var(--clr-blue-light) !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontSize: "15px",
        color: "var(--clr-blue-footer)",
        padding: "11px 26px 12px 12px",

        "&:focus": {
            borderRadius: 4,
            borderColor: "red",
        },
    },
}));

export const SearchButton = styled(Button)(() => ({
    backgroundColor: "var(--clr-btn-primary)",
    color: "var(--clr-white) !important",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "50px",
    "&:hover": {
        backgroundColor: "var(--clr-btn-primary) !important",
        color: "var(--clr-white) !important",
    },
}));

export const SearchJobsForm = (props) => {
    const { jobsSearchInfo, setJobsSearchInfo, handleSearchInput, handleJobsSearch, values, handleChange, setValues, setJobSearch, jobSearch, allCityLocation, setOpenAutoComplete, SearchLocation, handleToggle, error, resText, errInput, t, setShowForm, allSearchQueryParameters, matches } = props;
    return (
        <>
            <form onSubmit={handleJobsSearch} style={{ display: "flex", justifyContent: "center" }}>
                <Grid container sx={{ justifyContent: { xs: "center", md: "end" } }} gap={{ xs: 1, md: 2 }} >
                    <Grid item md={3} xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                sx: {
                                    ".MuiOutlinedInput-input": {
                                        padding: "10.7px 14px 10.7px 25px",
                                        backgroundColor: "white",
                                        borderRadius: "16px",
                                    },
                                },
                            }}
                            size="small"
                            placeholder="Enter Keyword"
                            value={jobsSearchInfo?.jobTitle}
                            onChange={handleSearchInput("jobTitle")}
                            sx={{
                                // width: "30% !important",
                                borderRadius: "12px",
                                "& fieldset": {
                                    borderRadius: "12px",
                                },
                            }}
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                    </Grid>
                    <Grid item md={3} xs={9.5}>
                        <Autocomplete
                            disablePortal
                            noOptionsText={"Start typing"}
                            id="combo-box-demo"
                            sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                    padding: "3.5px 14px 3.5px 18px !important",
                                    borderRadius: "12px",
                                    backgroundColor: "white"
                                },
                                // width: "30% !important",
                                // boxShadow: "0px 8px 18px rgba(69, 143, 246, 0.13)",
                                borderRadius: "12px",
                                // border: "1px solid rgba(69, 143, 246, 0.5) !important",
                            }}
                            value={jobsSearchInfo?.location}
                            onChange={(event, val, params) => {
                                setJobsSearchInfo({ ...jobsSearchInfo, location: val });
                                setJobSearch({ ...jobSearch, location: val });
                                setOpenAutoComplete(false);
                                window.onscroll = function () { };
                            }}
                            options={allCityLocation?.map(
                                (option) => option.cityWithState
                            )}
                            renderInput={(params) => (
                                <TextField
                                    onClick={handleToggle}
                                    onChange={(e) => {
                                        SearchLocation(e);
                                    }}
                                    placeholder="Select Location"
                                    {...params}
                                />
                            )}
                        />
                    </Grid>
                    {!matches ? <Grid item md={3}>
                        < Box >
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{
                                    color: "white",
                                    borderRadius: "12px",
                                    px: 2,
                                    py: 1.4,
                                    backgroundColor: "#395987 !important",
                                    letterSpacing: 1,
                                    fontSize: 16,
                                    fontWeight: "500 !important",
                                    lineHeight: "22px",
                                }}
                                startIcon={<SearchIcon />}
                                onClick={() => {
                                    handleJobsSearch();
                                    setShowForm(pre => !pre);
                                }}
                            >
                                {t("common:Search")}
                            </Button>
                        </Box>
                    </Grid>
                        :
                        <Grid item xs={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{
                                    color: "white",
                                    borderRadius: "12px",
                                    backgroundColor: "#395987 !important",
                                    width: "55px",
                                    height: "-webkit-fill-available"
                                }}
                                onClick={() => {
                                    handleJobsSearch();
                                    setShowForm(pre => !pre);
                                }}
                            >
                                <SearchIcon />
                            </Button>
                        </Grid>}
                </Grid>
            </form >
            {
                values.jobTitle === "" && (
                    <FormHelperText sx={{ color: "red", mb: 1 }}>
                        {t(errInput)}
                    </FormHelperText>
                )
            }
            {
                values.jobTitle !== "" &&
                (values.jobTitle === " " ||
                    resText.test(values.jobTitle) === false) && (
                    <FormHelperText sx={{ color: "red", mb: 1 }}>
                        {t(error)}
                    </FormHelperText>
                )}
        </>
    )
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SaveAndApplyToJobStatus = (props) => {
    const { appliedToJobStatus, setAppliedToJobStatus } = props;
    const open = appliedToJobStatus.success || appliedToJobStatus.failure;
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAppliedToJobStatus({ success: false, failure: false });
    }
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={(appliedToJobStatus.success && "success") || (appliedToJobStatus.failure && "error")}>
                {appliedToJobStatus.success && "Well done! We have sent your job application to recruiter."}
                {appliedToJobStatus.failure && "Something is not right! Please try again after sometime."}
            </Alert>
        </Snackbar>
    )
};

export const SaveOrDeleteJobStatus = ({ saveAndDeleteSavedJobStatus, setSaveAndDeleteSavedJobStatus }) => {
    const { saveSuccess, saveFailure, deleteSuccess, deleteFailure } = saveAndDeleteSavedJobStatus;
    const open = Boolean(saveSuccess || saveFailure || deleteSuccess || deleteFailure);
    const success = Boolean(saveSuccess || deleteSuccess);
    const failure = Boolean(saveFailure || deleteFailure);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaveAndDeleteSavedJobStatus({ saveSuccess: false, saveFailure: false, deleteSuccess: false, deleteFailure: false })
    };
    const severity = (success && "success") || (failure && "error");
    const message = (saveSuccess && "Job saved successfully.") || (deleteSuccess && "Job removed from saved jobs list.") || ((saveFailure || deleteFailure) && "Something went wrong! Please try again after some time.")
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    )
};

export const UploadResumeOrApply = (props) => {
    const { searchResult, openAddModal, hanldeCloseAddModal, matches, handleApplyForJob, loaderBtn } = props;
    const navigate = useNavigate();
    return (
        <Modal
            open={openAddModal}
            onClose={hanldeCloseAddModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={!matches ? uploadResumeStyle : uploadResumeStyleResponsive}>
                <Box sx={{ display: "flex", flexDirection: "column", }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ color: "var(--clr-blue-footer)", fontWeight: "600", fontSize: "18px" }} variant="h6" component="h2">
                            Attention!
                        </Typography>
                        <CancelIcon style={{ color: "var(--clr-white-icon)" }} onClick={hanldeCloseAddModal} />
                    </Box>
                    <Typography variant="body2" sx={{ mt: 2, py: 2, color: "var(--clr-gray-2)" }}>
                        Applying for jobs without uploading a resume to the profile may lead to rejection of the job application. Click here to upload resume.
                    </Typography>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex", justifyContent: "right", gap: 2, py: 1 }}>
                            <Button
                                variant="outlined"
                                onClick={() => navigate("/profile")}
                                sx={{ borderRadius: 16, borderWidth: "2px !important" }}
                            >
                                Upload Resume
                            </Button>
                            {loaderBtn ? (
                                <Button
                                    variant="contained"
                                    className="save-btn"
                                    sx={{ borderRadius: 16, px: "47px" }}
                                >
                                    <CircularProgress size={25} sx={{ color: "white" }} />
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    className="save-btn"
                                    onClick={() => handleApplyForJob(searchResult)}
                                    sx={{ borderRadius: 16 }}
                                >
                                    Apply Anyway
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};

export const SaveApplyViewJobButton = (props) => {
    const { pathname } = useLocation();
    const { user, resume, handleApplyForJob, showSingleJobData, handleModalForNoResumeAlert, handleDeleteSavedJobs, handleSaveJob, handleSingleJob, clickedJob, loaderBtn } = props;
    const inJobSearchPage = pathname?.includes("jobs") && pathname !== '/search-jobs' && pathname !== '/applied-jobs' && pathname !== '/saved-jobs' && !pathname?.includes('healthcare-jobs') && !pathname?.includes('jobseeker') && pathname !== '/manage-jobs-and-responses';

    const handleApplyButton = () => {
        if (!showSingleJobData?.appliedJob) {
            if (user?.name) {
                resume?.filename ? handleApplyForJob(showSingleJobData) : handleModalForNoResumeAlert();
            } else {
                handleApplyForJob(showSingleJobData);
            }
        }
    };

    return (
        <Box sx={{
            display: "flex", flexDirection: "column", gap: inJobSearchPage ? 1.4 : 1.25,
            px: { xs: 1.25, md: inJobSearchPage ? 2.5 : 0 },
            py: { xs: 1.25, md: 0 },
            my: inJobSearchPage ? { xs: 0, md: 2 } : 0
        }}>
            <Box sx={{ display: "flex", flexDirection: { xs: "row", md: "column" }, gap: 1.25, alignItems: "center" }}>
                {(loaderBtn && clickedJob?.vacancyID === showSingleJobData?.vacancyID) ? (
                    <Button
                        fullWidth
                        disabled
                        sx={{ order: { xs: 2, md: 1 }, borderRadius: "6px", py: 1.25, fontSize: inJobSearchPage ? 16 : 14 }}
                        variant="contained"
                    >
                        <CircularProgress size={25} sx={{ color: "white" }} />
                    </Button>
                ) : (
                    <Button
                        onClick={handleApplyButton}
                        disabled={showSingleJobData?.appliedJob}
                        fullWidth
                        sx={{ order: { xs: 2, md: 1 }, borderRadius: "6px", py: 1.25, fontSize: inJobSearchPage ? 16 : 14 }}
                        variant="contained"
                    >
                        {showSingleJobData?.appliedJob ? "Applied" : "Apply"}
                    </Button>
                )}
                <Button
                    onClick={() => showSingleJobData?.savedJob ? handleDeleteSavedJobs(showSingleJobData) : handleSaveJob(showSingleJobData)}
                    fullWidth
                    sx={{ order: { xs: 1, md: 2 }, borderRadius: "6px", py: 1.25, fontSize: inJobSearchPage ? 16 : 14 }}
                    variant="outlined"
                    startIcon={<BookmarkIcon fontSize="small" />}
                >
                    {showSingleJobData?.savedJob ? "Saved" : "Save"}
                </Button>
            </Box>

            <Box
                onClick={() => handleSingleJob(showSingleJobData)}
                sx={{ display: inJobSearchPage ? { xs: "none", md: "flex" } : "none", mx: "auto", alignItems: "center", gap: 1, color: "#395987", mt: inJobSearchPage ? { md: 1.25 } : 0, cursor: "pointer" }}
            >
                <OpenInNewIcon sx={{ fontSize: 16 }} />
                <Typography sx={{ fontSize: 14, fontWeight: 600, lineHeight: "19px", textDecoration: "underline" }}>
                    View more
                </Typography>
            </Box>
        </Box>
    )
}

export const RecentSearches = forwardRef(({ handleNavigate }, ref) => {
    const { pathname } = useLocation();
    const recentSearches = JSON.parse(localStorage.getItem("searchHistory"))
    const [searchHistory, setSearchHistory] = useState(recentSearches || []);

    function handleUpdateSearchHistory(newSearch) {
        const existingSearch = searchHistory?.find(search =>
            search?.jobTitle?.toLowerCase() === newSearch?.jobTitle?.toLowerCase() &&
            search?.location?.toLowerCase() === newSearch?.location?.toLowerCase()
        );
        if (existingSearch) return;

        const newSearchHistory =
            searchHistory.length === 5
                ? [newSearch, ...searchHistory.slice(0, 4)]
                : [newSearch, ...searchHistory];
        setSearchHistory(newSearchHistory);
        localStorage.setItem("searchHistory", JSON.stringify(newSearchHistory));
    }

    useImperativeHandle(ref, () => ({
        handleUpdateSearchHistory
    }));

    function handleRemoveSearchItem(index) {
        const newSearchHistory = searchHistory.filter((search, i) => i !== index);
        setSearchHistory(newSearchHistory);
        localStorage.setItem("searchHistory", JSON.stringify(newSearchHistory));
    }

    const homePage = pathname === "/";

    return (
        <Box>
            {searchHistory?.length > 0 && (
                <Box sx={{ display: "flex", justifyContent: { xs: "start", md: homePage ? "center" : "start" }, gap: { xs: 1.25, md: 2 }, flexWrap: "wrap", mt: { xs: 4, md: 2.5 } }}>
                    {searchHistory?.map((search, index) => (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 0.8, border: "1px solid #E4EEF5", borderRadius: "30px", color: "#6F7482", px: 1.25, py: 0.4, cursor: "pointer" }} key={index}>
                            <HistoryIcon onClick={() => handleNavigate(search)} sx={{ fontSize: 16 }} />
                            <Typography
                                onClick={() => handleNavigate(search)}
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "22px",
                                    backgroundColor: "#FFFFFF",
                                }}
                            >
                                {search?.jobTitle}{search?.location && `, ${search?.location?.split(",")[0]}`}
                            </Typography>
                            <ClearIcon onClick={() => handleRemoveSearchItem(index)} sx={{ fontSize: 16 }} />
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    )
});

export const TextWithLoader = ({ loading, text, color, size }) => {
    return loading ? <CircularProgress size={size || 26} sx={{ color: color || "white" }} /> : text;
};

export const AllPagePixelCode = () => {
    const GDPR = '$(GDPR)';
    const GDPR_CONSENT_755 = '$(GDPR_CONSENT_755)';
    return (
        <Box sx={{ height: "0px !important" }}>
            <img src={`https://secure.adnxs.com/px?id=${process.env.REACT_APP_ALL_PAGES_XANDR_ID}&seg=${process.env.REACT_APP_ALL_PAGES_XANDR_SEG}&redir=${window.location.href}&t=2`} width="1" height="1" />
            <img src={`https://ad.doubleclick.net/ddm/activity/src=${process.env.REACT_APP_COMMON_DCM};type=stand0;cat=med-a0;u1=${window.location.href};u2=[referrer];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?`} width="1" height="1" alt="" />
        </Box>
    )
}