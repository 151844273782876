import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import HospitalSignUpForm from '../HospitalSignUpForm/HospitalSignUpForm.view';
// import { SignupForm, Welcome } from "../../../components";
// import HospitalSignUpForm from "../../../components/HospitalSignUpForm/HospitalSignUpForm.view";
import JobRelatedInfo from '../JobRelatedInfo';
import Welcome from '../Welcome';
import SignupForm from './signUpForm.view';
import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider';
import { useEffect } from 'react';

const SetNewPassword = (props) => {
  const [focusedPassword, setFocusedPassword] = useState(false);
  const [values, setValues] = useState({
    password: '',
    confirmpassword: '',
  });
  const [errorPassword, setErrorPassword] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const token = localStorage.getItem('accessToken');

  const region = 'ap-south-1';
  const provider = new CognitoIdentityProvider({ region });
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  var poolData = {
    UserPoolId: `${process.env.REACT_APP_DOCTORS_FLOW_SIGNUP_USERPOOLID}`,
    ClientId: `${process.env.REACT_APP_DOCTORS_FLOW_SIGNUP_CLIENTID}`,
  };

  const location = useLocation();
  const banner_text = 'Welcome';

  useEffect(() => {
    if (token) {
      navigate('/jobseeker-login');
    }
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  let passwordRegex =
    /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

  const handlePasswordFocus = () => {
    setFocusedPassword(true);
  };

  const handleClickShowPassword = (psw) => {
    setValues({
      ...values,
      [psw]: !values[psw],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    try {
      const res = await provider.respondToAuthChallenge({
        ChallengeName: 'NEW_PASSWORD_REQUIRED',
        ClientId: poolData.ClientId,
        ChallengeResponses: {
          USERNAME: location.state.email,
          NEW_PASSWORD: values.password,
        },
        Session: location.state.Session,
      });
      let auth = res.AuthenticationResult;
      localStorage.setItem('accessToken', auth?.AccessToken);
      localStorage.setItem('idToken', auth?.IdToken);
      localStorage.setItem('refreshToken', auth?.RefreshToken);
      localStorage.setItem('flow', 'jobSeeker');
      navigate('/profile-home');
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Box
      maxWidth='xl'
      sx={{
        backgroundColor: '#F0F6FE',
        mx: 'auto',
        padding: !matches ? '1rem 3rem 3rem' : '',
      }}
    >
      {
      matches &&
      <Box sx={{ backgroundColor: "var(--clr-blue-light)", padding: "15px 0 15px 17px" , display: "flex", alignItems: "center"}}>
      <ArrowBackIcon onClick={() => navigate(-1)} sx={{color: "var(--clr-blue-footer)", mr: 3.1}}/><Typography variant="h6" sx={{ lineHeight: "24px", fontSize: "16px", fontWeight: "600", color: "var(--clr-blue-footer)" }}> </Typography>
      </Box>
      }
      <Grid container spacing={8} justifyContent='space-between'>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: 'white',
              px: 5,
              height: {xs:'70vh',md:'85vh'}

             }}
             style={{boxShadow: 'rgb(69 143 246 / 9%) 0px 9px 18px'}}
          >
            <Typography
              variant='h6'
              component='div'
              sx={{
                pt: 2.5,
                fontSize: !matches ? '24px' : '18px',
                fontWeight: '600',
                color: 'var(--clr-blue-footer)',
                textAlign: !matches ? 'center' : 'start',
                mb: !matches ? 3 : 2,
                ml: !matches ? '' : '-10px',
              }}
            >
              Create New Password
            </Typography>
            <Box sx={{display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              borderRadius: '6px',}}>
              <Box>
                <InputLabel sx={{ py: 0.5 }}>New Password</InputLabel>
                <TextField
                  sx={{
                    borderRadius: 1,
                    color: 'var(--clr-blue-footer)',
                    fontSize: '14px',
                  }}
                  InputProps={{
                    sx: {
                      '.MuiOutlinedInput-input': {
                        // border: "1px solid var(--clr-blue-light)",
                        padding: '10.5px 14px',
                      },
                      /* ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                              border: "1px solid var(--clr-blue-light)",
                            },
                            "&:hover": {
                              ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                border: "1px solid var(--clr-blue-primary)",
                              },
                            }, */
                    },
                    endAdornment: (
                      <InputAdornment sx={{ mr: 0.5 }} position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={()=>handleClickShowPassword('showPassword')}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {values.showPassword ? (
                            <VisibilityOff
                              sx={{ color: 'var(--clr-blue-footer)' }}
                            />
                          ) : (
                            <Visibility
                              sx={{ color: 'var(--clr-blue-footer)' }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disableUnderline
                  fullWidth
                  defaultValue={values.password}
                  // value={values.password}
                  onChange={handleChange('password')}
                  error={values.password === '' && errorPassword}
                  // onKeyDown={(props.pageType === "Register" && handleSubmit) || (props.pageType === "Login" && handleLogIn) || (props.pageType === "Create New Password" && hanldeResetPassword)}
                  type={values.showPassword ? 'text' : 'password'}
                  placeholder='Enter Password'
                  onBlur={handlePasswordFocus}
                  // focused={focusedPassword.toString()}
                />
                {values.password === '' && focusedPassword === true && (
                  <FormHelperText sx={{ color: 'red', mt: 0 }}>
                    Password is mandatory!
                  </FormHelperText>
                )}
                {values.password === '' && focusedPassword !== true && (
                  <FormHelperText sx={{ color: 'red', mt: 0 }}>
                    {errorPassword}
                  </FormHelperText>
                )}
                {props.pageType !== 'Login' &&
                  passwordRegex.test(values.password) === true && (
                    <FormHelperText sx={{ color: 'green', mt: 0 }}>
                      {/* {errorPassword} */}
                      Password is strong.
                    </FormHelperText>
                  )}
                {values.password !== '' &&
                  focusedPassword === true &&
                  passwordRegex.test(values.password) === false &&
                  focusedPassword === true && (
                    <FormHelperText sx={{ color: 'red', mt: 0 }}>
                      {/* {errorPassword} */}
                      Password must be at least 8 characters with at least 1 digit 
                    </FormHelperText>
                  )}
              </Box>
              <Box>
                <InputLabel sx={{ py: 0.5 }}>Confirm New Password</InputLabel>
                <TextField
                  sx={{
                    borderRadius: 1,
                    color: 'var(--clr-blue-footer)',
                    fontSize: '14px',
                  }}
                  InputProps={{
                    sx: {
                      '.MuiOutlinedInput-input': {
                        // border: "1px solid var(--clr-blue-light)",
                        padding: '10.5px 14px',
                      },
                      /* ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                              border: "1px solid var(--clr-blue-light)",
                            },
                            "&:hover": {
                              ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                border: "1px solid var(--clr-blue-primary)",
                              },
                            }, */
                    },
                    endAdornment: (
                      <InputAdornment sx={{ mr: 0.5 }} position='end'>
                        <IconButton
                        aria-label='toggle password visibility'
                        onClick={()=>handleClickShowPassword('showConfirmPassword')}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                         {values.showConfirmPassword ? (
                            <VisibilityOff
                              sx={{ color: 'var(--clr-blue-footer)' }}
                            />
                           ) : ( 
                            <Visibility
                              sx={{ color: 'var(--clr-blue-footer)' }}
                            />
                           )}
                      </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disableUnderline
                  fullWidth
                  defaultValue={values.confirmpassword}
                  // value={values.password}
                  onChange={handleChange('confirmpassword')}
                  error={values.confirmpassword === '' && errorPassword}
                  // onKeyDown={(props.pageType === "Register" && handleSubmit) || (props.pageType === "Login" && handleLogIn) || (props.pageType === "Create New Password" && hanldeResetPassword)}
                  type={values.showConfirmPassword ? 'text' : 'password'}
                  placeholder='Enter Password'
                  onBlur={handlePasswordFocus}
                  // focused={focusedPassword.toString()}
                />
                {values.confirmpassword === '' && focusedPassword === true && (
                  <FormHelperText sx={{ color: 'red', mt: 0 }}>
                    Password is mandatory!
                  </FormHelperText>
                )}
                {values.confirmpassword === '' && focusedPassword !== true && (
                  <FormHelperText sx={{ color: 'red', mt: 0 }}>
                    {errorPassword}
                  </FormHelperText>
                )}

                {values.password !== values.confirmpassword &&
                  values.confirmpassword !== '' && (
                    <FormHelperText sx={{ color: 'red', mt: 0 }}>
                      {/* {errorPassword} */}
                      Password does not match
                    </FormHelperText>
                  )}
              </Box>
              <Button
                variant='contained'
                type='submit'
                onClick={(event) => handleSubmit()}
                sx={{ p: 1, my: 3, borderRadius: 16, width: '100%' }}
              >
                {loader ? (
                  <CircularProgress size={25} sx={{ color: 'white' }} />
                ) : (
                  'Submit'
                )}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Welcome text={banner_text} />
        </Grid>
      </Grid>
      {error && (
        <Snackbar
          open={error}
          autoHideDuration={4000}
          onClose={() => setError(false)}
        >
          <Alert onClose={() => setError(false)} severity='error'>
            Some Error occurred Please try again
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default SetNewPassword;
