import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import ApplicationStateProvider from "./context/ApplicationStateProvider";
import "./i18n";
import windowProxy from "./components/PreventNavigationPopup/WarningModal/ProxyWindow";

if (process.env.NODE_ENV !== "development") {
  console.log = () => { };
  console.debug = () => { };
  console.info = () => { };
  console.warn = () => { };
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ApplicationStateProvider>
        <Router window={windowProxy}>
          <App />
        </Router>
      </ApplicationStateProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
