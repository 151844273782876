import React, { createContext } from "react";
import useUserState from "../hooks/useUserState";

// Context
export const ApplicationStateContext = createContext();

const ApplicationStateProvider = ({ children }) => {
  const allContext = useUserState();
  return (
    <ApplicationStateContext.Provider value={allContext}>{children}</ApplicationStateContext.Provider>
  );
};

export default ApplicationStateProvider;
