import { Auth } from "aws-amplify";
import { gqlOpenQuery } from "./index.js";

export const saveErrorToDB = async (errors, query, flow) => {
  let userID;
  try {
    const user = await Auth.currentAuthenticatedUser();
    userID = user?.attributes?.sub;
  } catch (err) {
    userID = "";
  }

  const isArray = Array.isArray(errors);

  if (isArray) {
    errors.map(async (error) => {
      const errorMessage = error.message.replace(/[^a-zA-Z0-9]/g, " ");
      const match = query?.match(/{\s*(\w+)\s*\(/);
      const str = match ? match[1] : "";
      const errorPath = error?.path?.join(",") || str;
      if (
        error.errorType === "Data not found" ||
        error.errorType === "Exceptional condition" ||
        errorPath === "saveErrorToDB"
      ) {
        // console.log('skip',errorPath)
        return;
      }

      const SAVE_ERROR_TO_DB = {
        query: `mutation MyMutation {
            saveErrorToDB(
              errorMessage: "${errorMessage}",
              errorType:"${error?.errorType ? error?.errorType : ""}",
              errorPath:"${errorPath ? errorPath : ""}",
              userID:"${userID || ""}",
              flow:"${flow}"
            )
          }`,
        variables: null,
        operationName: "MyMutation",
      };
      await gqlOpenQuery(SAVE_ERROR_TO_DB, null)
        .then((res) => res.json())
        .then((data) => {
          return data;
        });
    });
  } else {
    const errorMessage = errors.message.replace(/[^a-zA-Z0-9]/g, " ");
    const match = query?.match(/{\s*(\w+)\s*\(/);
    const str = match ? match[1] : "";
    const errorPath = errors?.path?.join(",") || str;
    if (
      errors.errorType === "Data not found" ||
      errors.errorType === "Exceptional condition" ||
      errorPath === "saveErrorToDB"
    ) {
      // console.log('skip',errorPath)
      return;
    }

    const SAVE_ERROR_TO_DB = {
      query: `mutation MyMutation {
            saveErrorToDB(
              errorMessage: "${errorMessage}",
              errorType:"${errors?.errorType ? errors?.errorType : ""}",
              errorPath:"${errorPath ? errorPath : ""}",
              userID:"${userID || ""}",
              flow:"${flow}"
            )
          }`,
      variables: null,
      operationName: "MyMutation",
    };
    await gqlOpenQuery(SAVE_ERROR_TO_DB, null)
      .then((res) => res.json())
      .then((data) => {
        return data;
      });
  }
};
