import { Amplify, Auth } from "aws-amplify";
import jwt_decode from "jwt-decode";
import { saveErrorToDB } from "./handleBackendErrors";
Amplify.configure({
    Auth: {
        region: "ap-south-1",
        userPoolId: `${process.env.REACT_APP_HOSPITAL_FLOW_SIGNUP_USERPOOLID}`,
        userPoolWebClientId: `${process.env.REACT_APP_HOSPITAL_FLOW_SIGNUP_CLIENTID}`,
    },
});

export const QUERY_GETHCIINDUSTRY = {
    query: `query MyQuery {
        getHCIIndustry {
          hciID
          hciType
          industry
          specialty
          status
        }
    }`
}

export const QUERY_GETHOSPITALTYPE = {
    query: `query MyQuery {
        getHospitalTypes {
          htmID
          type
        }
    }`
}

export const  QUERY_GETALLCOURSES={
    query:`query MyQuery{
        getEducationCourses {
            course
            emID
          }
    }`
}

export const QUERY_GETHOSPITALUSERS = {
    query: `query MyQuery {
         getHospitalUsers {
            email
            huID
            accessJobPosting
            accessResumeDB        
            name
            status
            userID
            phoneNumber
        }
    }`
};

export const QUERY_GETMYPROFILE = {
    query: `query MyQuery {
        getMyProfile  {
            huID
            email
            phoneNumber
            accessJobPosting
            accessResumeDB
            hospitalID
            adminUser
            name
            status   
            systemUser
        }   
    }`
};

export const QUERY_GETJOBPOSTINGPLANS = {
    query: `query MyQuery {
        getJobPostPlans {
          validity
          terms
          taxRate
          subtext
          spID
          recommended
          price
          name
          features
        }       
    }`
};

export const QUERY_GETRESUMEDBPLANS = {
    query: `query MyQuery {
        getResumeDBPlans {
            features
            name
            price
            recommended
            spID
            subtext
            validity
            terms
            taxRate
        }
    }`
};

export const QUERY_GETSUBSCRIBEDPLANS = {
    query: `query MyQuery {
        getSubscribedPlans {
          initiatedAt
          name
          orderID
          subtext
          quantity
          amount
          status
          transactionID
          activeStatus
        }
      }`
};

export const QUERY_GETHOSPITAL = {
    query: `query MyQuery {
        getHospital {
            type
            taxNumber
            newsletter
            name
            locationID
            location
            hospitalID
            description
            contactPhone
            contactName
            contactEmail
        }
    }`,
};

export const QUERY_VACANCIES = {
    query: `query MyQuery {
        getVacancies {
            description
            employmentType
            expMax
            expMin
            isSalaryDisclosed
            lastDateToApply
            location
            locationID
            maximumSalary
            minimumSalary
            postedOn
            qualification
            qualificationID
            responses
            status
            vacancyID
            vacancyType
            postedBy
            numberOfVacancies
            announcedDate
            includeWalkInInterviewDetails
            userAddedJobRoleID
          }
    }`
};

export const QUERY_GETHRFOLDER = {
    query: `query MyQuery {
        getFolders {
            folderID
            name
            profileCount
            userName
        }
        getFolderWiseProfilesCount {
            folderID
            profiles
        }
    }`
};

export const QUERY_HOSPITALDETAILS = {
    query: `query MyQuery {
        getHospitalDetails {
          about
          additionalPhone1
          address
          areaName
          city
          contactPhone
          name
          contactName
          displayHospitalName
          contactPerson
          country
          designation
          gstin
          hospitalID
          hciID
          pan
          KYCstatus
          phoneVerified
          pincode
          profilePicURL
          reportingManager
          role
          state
          video
          website
          locationID
          isOtherCity
          phoneVerified
          additionalPhoneVerified
          freeJobPopupEnabled
         }
    }`
};

export const QUERY_GET_RECRUITER_DETAILS = {
    query: `query MyQuery {
        getRecruiterDetails {
          about
          address
           email
          industry
          name
          contactName
          phone
        }
      }`
};

export const QUERY_GETACTIVEJOBPOSTSUBSCRIPTION = {
    query: `query MyQuery {
        getActiveJobPostSubscription {
          active
          creditsLeft
        }
      }`
};

export const QUERY_GETACTIVERESUMEDBSUBSCRIPTION = {
    query: `query MyQuery {
        getActiveResumeDBSubscription {
          creditsLeft
          active
        }
      }`
};

export const QUERY_GETACTIVESUBSCRIPTIONS = {
    query: `query MyQuery {
        getActiveSubscriptions {
          credits
          createdOn
          creditsLeft
          freeCredits
          name
          subtext
          type
          validUpto
        }
      }`
};

export const QUERY_HOSPITALPICTURES = {
    query: `query MyQuery {
        getHospitalPictures {
            haID
            name
            url
          }
    }`
};

export const QUERY_DESIGNMASTER = {
    query: `query MyQuery {
        getDesignationMaster {
            dmID 
            name
        }
    }`
};

export const GET_SEPCIALIZATION = {
    query: `query MyQuery {
        getSpecialization {
            emID
            specialization
        }
    }`
};

export const GET_SKILLS = {
    query: `query MyQuery {
        getSkillMaster {
          name
          skillID
        }
      }`
};

export const GET_CITY = {
    query: `query MyQuery {
        searchCity(city: "") {
          city
          cityWithState
          country
          lmID
          state
        }
      }`
};

export const QUERY_DEPARTMENTS = {
    query: `query MyQuery {
        getDepartments {
            departmentID
            name
        }
    }`
};

export const QUERY_HOSPITALMASTER = {
    query: `query MyQuery {
        getHospitalMaster {
            hmID 
            name
        }
    }`
};

export const QUERY_NOTICEMASTER = {
    query: `query MyQuery {
        getNoticePeriodMasters {
            npID 
            notice
        }
    }`
};

export const QUERY_GETQUALIFICATIONS = {
    query: `query MyQuery {
        getQualifications {
          emID
          qualification
        }
    }`
};

export const QUERY_GETSPECIALIZATION = {
    query: `query MyQuery {
        getSpecialization {
            emID
            specialization
            qualification
            healthcareIndustry
            course
        }
    }`
};

export const QUERY_GET_INSTITUTE_TYPE = {
    query: `query MyQuery {
      getHITypeMaster {
        hitmID
        type
      }
    }`,
};

export const QUERY_GET_HEALTHINDUSTRY = {
    query: `query MyQuery {
      getIndustry {
        healthcareIndustry
      }
    }
  `};

export const QUERY_UNIVERSITYID = {
    query: `query MyQuery {
        getUniversityMaster   {
            name 
            umID
        }
    }`
};

export const QUERY_COURSEID = {
    query: `query MyQuery {
        getCourseMaster {
            cmID
            name
        }
    }`
};

export const QUERY_GET_INDUSTRY = {
    query: `query MyQuery {
      getHCIIndustry {
        hciID
        industry
        specialty
      }
    }`,
};

export const QUERY_GETRECENTSEARCH = {
    query: `query MyQuery {
      getRecentSearch {
        activeIn
        activelySearching
        allKeywords
        anyKeywords
        createdAt
        currentLocation
        userID
        ug_university
        ug_qualification
        ug_passingYearTo
        ug_passingYearFrom
        ug_courseType
        ug_course
        searchName
        savedBy
        salaryRangeStart
        salaryRangeEnd
        salaryNotMention
        rsID
        ps_minimumAge
        ps_maximumAge
        ps_gender
        preferredLocation
        ppg_university
        ppg_qualification
        ppg_passingYearTo
        ppg_passingYearFrom
        ppg_courseType
        ppg_course
        pg_university
        pg_qualification
        pg_passingYearTo
        pg_passingYearFrom
        pg_courseType
        pg_course
        isResumeAttached
        isVideoResume
        isPhoneVerified
        isOutsideIndia
        isEmailVerified
        isContactByPhone
        isAnywhereFromIndia
        hospitalID
        experienceTo
        experienceFrom
        excludesKeywords
        emp_noticePeriodID
        emp_jobRole
        emp_instituteName
        emp_industry
        emp_excludeKeywords
        emp_department
        ed_specialization
      }
    }`,
};

export const QUERY_PROFILE_STATUS = {
    query: `query MyQuery {
      getHospitalProfileStatus {
        status
        caption
      }
    }`,
};

export const QUERY_GETSAVEDRESUMESEARCH = {
    query: `query MyQuery {
        getResumeSearch {
            userID
            ug_university
            ug_passingYearTo
            ug_qualification
            ug_passingYearFrom
            ug_courseType
            ug_course
            searchName
            savedBy
            salaryRangeStart
            salaryRangeEnd
            salaryNotMention
            rsID
            ps_minimumAge
            ps_maximumAge
            ps_gender
            preferredLocation
            ppg_university
            ppg_qualification
            ppg_passingYearTo
            ppg_passingYearFrom
            ppg_courseType
            ppg_course
            pg_university
            pg_qualification
            pg_passingYearTo
            pg_passingYearFrom
            pg_courseType
            pg_course
            isVideoResume
            isResumeAttached
            isPhoneVerified
            isOutsideIndia
            isEmailVerified
            isContactByPhone
            isAnywhereFromIndia
            hospitalID
            experienceTo
            experienceFrom
            excludesKeywords
            emp_noticePeriodID
            emp_jobRole
            emp_instituteName
            emp_industry
            emp_excludeKeywords
            emp_department
            createdAt
            anyKeywords
            currentLocation
            ed_specialization
            allKeywords
            activelySearching
            activeIn
        }
      }`,
};

export const QUERY_GETRECRUITERREMINDER = {
    query: `query MyQuery {
        getProfileReminders {
          reminderBy
          prID
          reminderOn
          userID
          hospitalID
          isRead
          userName
          jaID
          vacancyID
        }
      }`,
};

export const QUERY_GET_RECRUITER_NOTIFICATION = {
    query: `query MyQuery {
      getRecruiterNotification {
        description
        redirectTo
        rnID
        title
        type
        createdAt
        isRead
        vacancyID
      }
    } 
  `
};

export const QUERY_GET_RECRUITER_UNREAD_REMINDERS = {
    query: `query MyQuery {
        getReminderCount {
          totalReminder
        }
      }`
};

export const QUERY_GET_RECRUITER_UNREAD_NOTIFICATION = {
    query: `query MyQuery {
      getRecruiterNotificationCount {
        unreadCount
      }
    }`
};

export const GET_STATE_MASTER = {
    query: `query MyQuery {
        getStateMaster(country: "INDIA") {
          state
      }
    }`,
    variables: null,
    operationName: "MyQuery",
};

export const QUERY_GET_JOB_STATUS = {
    query: `query MyQuery {
        getJobStatus {
          status
        }
      }`
};

export const QUERY_GET_DRAF_POSTED_BY = {
    query: `query MyQuery {
        getDraftPostedBy {
          name
          userID
        }
      }`
};

export const QUERY_GET_JOB_POSTED_BY = {
    query: `query MyQuery {
        getJobPostedBy {
          name
          userID
        }
      }`
};

export const QUERY_GET_DRAFT_JOB_COUNT = {
    query: `query MyQuery {
        getDraftJobCount
      }`
};

export const QUERY_GET_SAVED_JOBSEEKER = {
    query: `query MyQuery {
        getSavedJobseeker {
          createdAt
          hospitalID
          jobseekerID
          savedBy
          sjID
        }
      }`
};

export const QUERY_GET_CITY_MASTER = {
    query: `query MyQuery {
        getCityMaster {
          city
          cityWithState
          country
          lmID
          state
        }
      }`
};

export const QUERY_GET_HEALTH_INSTITUTE_MASTER = {
    query: `query MyQuery {
        getHealthInstituteMaster {
          himID
          name
        }
      }`
};

export const QUERY_GET_SEARCH_JOB_ROLES = {
    query: `query MyQuery {
        searchJobRole(role: "") {
          dmID
          name
        }
      }`
};

export const QUERY_GET_SEARCH_DEPARTMENTS = {
    query: `query MyQuery {
        searchDepartment(name: "") {
          name
          departmentID
        }
      }`
};

export const refreshHospitalJwtToken = async () => {
    const refresh_token = localStorage.getItem("refreshToken");
    if (refresh_token?.length) {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
                if (session) {
                    const { idToken, refreshToken, accessToken } = session;
                    const id_token = idToken.jwtToken
                    const access_token = accessToken.jwtToken
                    const refresh_token = refreshToken.token

                    localStorage.setItem("accessToken", access_token);
                    localStorage.setItem("idToken", id_token);
                    localStorage.setItem("refreshToken", refresh_token);
                    return access_token
                } else {
                    localStorage.clear()
                    window.location.href = "/recruiters-login";
                }
            });
        }
        catch {
            localStorage.clear()
            window.location.href = "/recruiters-login";
        }
    } else {
        if (window.confirm("Something went wrong! \r\nPlease login to continue.")) {
            window.location.href = "/recruiters-login";
            localStorage.clear();
        } else {
            window.location.href = "/";
            localStorage.clear();
        }
    }
}

export const gqlquery = async (query, variables) => {
    const gquery = {
        query: query.query,
        variables: variables
    };
    const access_token = localStorage.getItem("accessToken");
    let final_token;

    try {
        jwt_decode(access_token, { header: true });
        let decoded = jwt_decode(access_token);
        if (decoded.exp < (new Date().getTime() + 1) / 1000) {
            final_token = await refreshHospitalJwtToken();
        } else {
            final_token = access_token;
        }

        const response = await fetch(
            `${process.env.REACT_APP_HOSPITAL_FLOW_GRAPHQL_MAIN_URL}`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authority:
                        `${process.env.REACT_APP_HOSPITAL_FLOW_AUTHORITYL}`,
                    authorization: final_token,
                    referer: `${process.env.REACT_APP_HOSPITAL_FLOW_REFERER}`,
                    "accept-language": "en-US,en;q=0.9",
                },
                body: JSON.stringify(gquery),
            }
        );
        const clonedResponse = response.clone();
        const responseData = await clonedResponse.json();
        if (responseData.errors) {
            const { errors } = responseData;
            saveErrorToDB(errors, query.query, 'Recruiter');
        }
        return response;
    } catch (error) {

        const refresh_token = localStorage.getItem("refreshToken");

        if (refresh_token) {
            final_token = await refreshHospitalJwtToken();
        }

        const response = await fetch(
            `${process.env.REACT_APP_HOSPITAL_FLOW_GRAPHQL_MAIN_URL}`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authority:
                        `${process.env.REACT_APP_HOSPITAL_FLOW_AUTHORITYL}`,
                    authorization: final_token,
                    referer: `${process.env.REACT_APP_HOSPITAL_FLOW_REFERER}`,
                    "accept-language": "en-US,en;q=0.9",
                },
                body: JSON.stringify(gquery),
            }
        );
        const clonedResponse = response.clone();
        const responseData = await clonedResponse.json();
        if (responseData.errors) {
            const { errors } = responseData;
            saveErrorToDB(errors, query.query, 'Recruiter');
        }
        return response;
    }
}

export const gqlOpenQuery = async (query, variables) => {
    try{
    const gquery = {
        query: query.query,
        variables: variables,
    }
    const response = await fetch(
        `${process.env.REACT_APP_FAQ_FLOW_GRAPHQL_MAIN_URL}`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authority: `${process.env.REACT_APP_FAQ_FLOW_AUTHORITYL}`,
                referer: "https://ap-south-1.console.aws.amazon.com",
                "accept-language": "en-US,en;q=0.9",
                "x-api-key": `${process.env.REACT_APP_FAQ_FLOW_X_API_KEY}`,
                "X-Amz-User-Agent": `${process.env.REACT_APP_FAQ_FLOW_USER_AGENT}`,
            },
            body: JSON.stringify(gquery),
        }
    )
    const clonedResponse = response.clone();
        const responseData = await clonedResponse.json();
        if (responseData.errors) {
            const { errors } = responseData;
            saveErrorToDB(errors, query.query, 'Open');
        }
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }   
}