import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCHTDVWe4RLPTRcLbwAZIaBZR8BrpnOlJ0",
  authDomain: "medlink-jobs.firebaseapp.com",
  projectId: "medlink-jobs",
  storageBucket: "medlink-jobs.appspot.com",
  messagingSenderId: "35152628598",
  appId: "1:35152628598:web:27a0e5f426650e0a0a0857",
  measurementId: "G-R419PNWN3M"
};

// initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


//----------Vapid key should come from env file-----
// const {REACT_APP_VAPID_KEY} = process.env;
// const publicKey = REACT_APP_VAPID_KEY;

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});


export const requestForToken = () => {
  return new Promise((resolve,reject)=>{
    getToken(messaging, {vapidKey: 'BP8POmyYCJsOpKte1zB6typNWNxmAlR5Ezx_RPAo_18-lfTDoc5q7bfUvyVDsGS4yoYLoP8VuE8Z3fEJdV8nqkM'}).then((currentToken) => {
      if (currentToken) {
        resolve(currentToken) ;
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      reject(err)
  });
})
}


  