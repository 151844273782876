import { Box, Button, MobileStepper, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import React, { useState } from 'react'
// import frame1 from "../../assets/images/rf/Recruiter Login-signup Illustration1.svg";
import frame1 from "../../assets/images/rf/Job offers-pana 1.svg";
import frame2 from "../../assets/images/rf/Recruiter Login-signup Illustration2.svg";
import frame3 from "../../assets/images/rf/Select-pana 3.png";
import frame4 from "../../assets/images/rf/Recruiter Login-signup Illustration3.svg";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Data = [
    {
        id: "1",
        label: "Job Posting",
        description: "Post a Job and Find Your Ideal Candidate. Reach Top Talent with Ease.",
        imgPath: frame1,
        button:"Know More"
    },
    {
        id: "2",
        label: "Search Candidates",
        description: "Unlock Challenges in Hiring with MedLink Jobs Search Candidates. Access Jobseekers Directly Through MedLink Jobs.",
        imgPath: frame2,
        button:"Know More"
    },
    {
        id: "3",
        label: "Staffing Solutions",
        description: "Streamline Your Healthcare Staffing with MedLink Job Board's Staffing Solutions. Efficient and Effective Staffing Solutions for Healthcare.",
        imgPath: `${frame3}`,
        button:"Know More"
    },
    {
        id: "4",
        label: "Branding Solutions",
        description: "Enhance Your Hospital's Brand with MedLink Jobs Branding Solutions. Elevate Your Hospital's Image with Effective Branding Solutions.",
        imgPath: `${frame4}`,
        button:"Know More"
    }
];

function TestimonialRf() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = Data.length;
    const navigate = useNavigate();
    const { t } = useTranslation(["landing"]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const redirectToRelevantPage = (e, activeStep) => {
        if (e.activeStep === 0) {
            navigate("/post-jobs")
        } else if (e.activeStep === 1) {
            navigate("/search-candidate")
        } else if (e.activeStep === 2) {
            navigate("/staffing-solutions")
        }else {
            navigate("/branding-solutions")
        }
    }

    return ( 
        <Box sx={{
            display: "flex",
            flexDirection: "column", m: "auto",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Box
                sx={{
                    mx: "auto",
                    mt: "30px",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    overflowX:"hidden"
                }}
            >
                <AutoPlaySwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {Data.map((item, index) => (
                        <Box key={index} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Box sx={{ display: "flex", justifyContent: "center",overflowX:"hidden" }}>
                                <img src={item.imgPath} alt='RfIllustration' />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                                <Typography sx={{ fontSize: "20px", fontWeight: 700, color: "#FFFFFF" }}>
                                    {item.label}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", width: 345, mx: "auto" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#FFFFFF", textAlign: "center" }}>
                                    {item.description}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", }}>
                                <Button variant='contained' sx={{ borderRadius: "24px", border: "2px solid #FFFFFF", marginBottom: 0.5, marginTop: 1 }}
                                    onClick={(e) => redirectToRelevantPage({ activeStep }, e)}
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                                        {item.button}
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    ))}
                </AutoPlaySwipeableViews>
            </Box>
            <div
                style={{
                    display: "flex",
                    justifyContent: matches ? "center" : "center",
                }}
            >
                <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    sx={{}}
                >
                    {theme.direction === "rtl" ? (
                        <NavigateBeforeIcon sx={{ ...swipeNavigateIconStyle }} />
                    ) : (
                        <NavigateBeforeIcon sx={{ ...swipeNavigateIconStyle }} />
                    )}
                </Button>
                <MobileStepper
                    variant="dots"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ bgcolor: "#395987", '& .MuiMobileStepper-dot': { mx: "9px", border: "1px solid #FFFFFF", background: "#395987" }, '& .MuiMobileStepper-dotActive': { backgroundColor: "#FFFFFF" } }}
                />
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                    sx={{ px: 0, py: 0 }}
                >
                    {theme.direction === "rtl" ? (
                        <NavigateNextIcon sx={{ ...swipeNavigateIconStyle }} />
                    ) : (
                        <NavigateNextIcon sx={{ ...swipeNavigateIconStyle }} />
                    )}
                </Button>
            </div>
        </Box>
    )
}

const swipeNavigateIconStyle = {
    color: "#FFFFFF"
}

export default TestimonialRf;